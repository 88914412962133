#auth-layout {
    display: flex;
    justify-content: center;
    align-items: center;
}

#auth-layout .auth-layout-card {
    width: 25%;
}

@media screen and (max-width: 1439px) {
    #auth-layout .auth-layout-card {
        width: 40%;
    }
}

@media screen and (max-width: 1023px) {
    #auth-layout .auth-layout-card {
        width: 80%;
    }
}
