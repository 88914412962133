.user-page-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-page-header > h3 {
    display: inline;
    margin-bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}