#root {
  width: 100vw;
  height: 100vh;
}

.cover-parent {
  width: 100%;
  height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}